import React from "react"
/** @jsx jsx */
import { Box, Grid, jsx } from "theme-ui"
const HeaderBlockColorNews = () => {
  return (
    <Box
      sx={{
        background:
          "linear-gradient(90deg, rgba(244,5,88,1) 0%, rgba(226,30,54,1) 100%)",
        transform: "rotate(-3deg) skew(-3deg) !important",
        py: "230px",
        mt: "-230px",
        mb: 4,
      }}
    ></Box>
  )
}
export default HeaderBlockColorNews
