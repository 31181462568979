/** @jsx jsx */
import { jsx } from "theme-ui"
import React, { useState, useCallback } from "react"
import { darken, lighten } from "@theme-ui/color"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import { Box, Flex, Grid } from "@theme-ui/components"
import SanitizedHTMLBodyCopy from "../components/sanitizedHTMLBodyCopy"
import { Collapse } from "react-collapse"
import { FaArrowDown } from "react-icons/fa"
import ShareIcons from "../components/shareIcons"
import HeaderBlockColor from "../components/headerBlockColor"
import HeaderBlockColorNews from "../components/headerBlockColorNews"

export const query = graphql`
  query SingleNewsQuery($slug: String!) {
    strapiNewsItem(slug: { eq: $slug }) {
      title
      date
      dateFormatted: date(formatString: "MMMM DD, YYYY")
      content
      blurb
      slug
      img {
        localFile {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      img_alt
      news_categories {
        tag
      }
    }
    defaultImage: file(relativePath: { eq: "default-news-thumb.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    site {
      siteMetadata {
        siteURL
      }
    }
  }
`

const NewsPage = ({ data, location }) => {
  const mainImg = data.strapiNewsItem.img
    ? data.strapiNewsItem.img.localFile.childImageSharp.fluid
    : data.defaultImage.childImageSharp.fluid
  const mainImgAlt = data.strapiNewsItem.img_alt
    ? data.strapiNewsItem.img_alt
    : "Default News Artwork"

  return (
    <Layout>
      <SEO title={`${data.strapiNewsItem.title} | News`} />
      <HeaderBlockColorNews />

      <Flex
        sx={{
          mx: [3, 4, 5],
          mt: "-280px",
          position: "relative",
          flexDirection: ["row", "row"],
          flexWrap: "wrap",
        }}
      >
        <Box
          sx={{
            width: ["100%", "50%", "40%"],
            pr: [0, 3],
            "& h1": {
              color: "white",
              fontSize: [5, 6],
              mb: 0,
            },
            "& h2": {
              mt: [1, 3],
            },
          }}
        >
          <h1>{data.strapiNewsItem.title}</h1>
          <h2>{data.strapiNewsItem.dateFormatted}</h2>
        </Box>

        <Img
          fluid={mainImg}
          alt={mainImgAlt}
          sx={{
            height: ["250px", "320px", "400px"],
            width: ["100%", "50%", "60%"],
            border: "2px solid black",
          }}
        />
      </Flex>
      <Box sx={{ mx: [4, 5], fontSize: 3, color: "muted", pb: 4, pt: [2, 4] }}>
        <SanitizedHTMLBodyCopy content={data.strapiNewsItem.content} />
      </Box>
      <Box
        sx={{
          mx: 0,
          mt: -3,
          mb: 2,
          display: "flex",
          justifyContent: "center",
          alignSelf: "center",
          position: "relative",
        }}
      >
        <Link
          to="/news"
          sx={{
            bg: "accent",
            color: "white",
            py: 2,
            px: 3,
            textDecoration: "none",
            transition: "background 0.3s ease-in-out",
            "&:hover": {
              background: darken("accent", 0.1),
            },
          }}
        >
          Back to News
        </Link>
      </Box>
    </Layout>
  )
}

export default NewsPage
